import React from 'react';
import styled from 'styled-components';
import BannerTxt from 'assets/images/home/banner_txt.png';
import { TYPE } from 'theme';
import Button from 'components/Button/button';
import { useHistory } from 'react-router-dom';

const BannerImg = styled.img`
    width: 60%;
`;

const Title = styled.div`
    text-align: center;
    background: linear-gradient(180deg, #be692a 0%, #e8c074 100%);
    font-size: 58px;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 600;
    margin-top: 24px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 28px;
    `};
`;

export default function Banner() {
    const history = useHistory();
    return (
        <>
            <Title>
                SEAMLESS STAKING METIS <br /> FOR PASSIVE EARNINGS
            </Title>

            <TYPE.main
                color="white01"
                fontSize={16}
                marginTop={34}
                textAlign="center"
            >
                Stake Metis to mint eMetis, use eMetis in seMetis vault to earn
                sequencer rewards
                <br />
                OR
                <br />
                use eMetis/seMetis in DeFi protocols to maximize returns
            </TYPE.main>

            <Button
                type="primary"
                onClick={() => {
                    history.push('/mint');
                }}
                style={{ marginTop: 82 }}
            >
                Stake Metis
            </Button>

            <a href="#audits" style={{ textDecoration: 'none' }}>
                <TYPE.main
                    color="mainColor"
                    fontSize={14}
                    marginTop={22}
                    style={{ cursor: 'pointer' }}
                >
                    Audits &gt;
                </TYPE.main>
            </a>
        </>
    );
}
