import Button from 'components/Button';
import { AutoColumn } from 'components/Column';
import Modal from 'components/Modal';
import { observer } from 'mobx-react-lite';
import React from 'react';
import application from 'stores/application';
import styled, { createGlobalStyle } from 'styled-components';
import { TYPE } from 'theme';

const StyledAutoColumn = styled(AutoColumn)`
    justify-items: center;
    gap: 20px;
    margin: 0 auto;
    margin-top: 20px;

    button {
        width: 220px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const PreStakingModalStyled = createGlobalStyle`
    #preStakingModal {
        left: 50%;
        top:50%;
        right: unset;
        bottom: unset;
        transform: translate(-50%, -50%);
         ${({ theme }) => theme.mediaWidth.upToSmall`
        z-index: 9;
        `};
        .MuiBackdrop-root {
            top: 80px;

             ${({ theme }) => theme.mediaWidth.upToSmall`
          
            `};
        }
    }
`;

function PreStakingModal() {
    return (
        <Modal
            id="preStakingModal"
            isOpen={application.isPreStakingModalOpen}
            disableBackgroundClick
            hideClose

            // onClose={() => {
            //     application.setIsPreStakingModalOpen(false);
            // }}
        >
            <StyledAutoColumn>
                <TYPE.main fontSize={20} textAlign={'center'} fontWeight={600}>
                    Coming Soon
                </TYPE.main>
                <TYPE.main fontSize={16} textAlign={'center'}>
                    This feature is not opened yet at pre-staking stage.
                </TYPE.main>
            </StyledAutoColumn>
            <PreStakingModalStyled />
        </Modal>
    );
}

export default observer(PreStakingModal);
