import { NETWORK_CONFIG } from 'constant';
import user from 'stores/user';

export default function useExploreLink() {
    const { chainId } = user;
    const { explore } = NETWORK_CONFIG[chainId];

    function getAddressUrl(url: string) {
        return `${explore}/address/${url}`;
    }

    return {
        getAddressUrl,
    };
}
