import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import LogoIconImage from 'assets/images/header/ic_logo@2x.png';
import Button from 'components/Button';
import application from 'stores/application';
import { observer } from 'mobx-react';

const HeaderWrapper = styled.div`
    position: fixed;
    z-index: 88;
    left: 0;
    top: 0;
    width: 100%;
    height: 124px;
    background-color: transparent;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 32px 72px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    height: 90px;
    `};

    .header-link {
        margin-right: 40px;
        text-decoration: none;
        color: ${({ theme }) => theme.c.white01};
        font-weight: 500;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        display: none;
        `};

        &:hover {
            color: ${({ theme }) => theme.c.mainColor};
        }
    }

    &.showBg {
        background: #000;

        transition: all 0.3s linear;
    }
`;

const Logo = styled.img`
    width: 112px;
    height: 38px;
`;

const RightContainer = styled.div`
    display: flex;
    height: 100%;
    flex: 1;
    align-items: center;
    justify-content: flex-end;
`;

const BoostTooltips = styled.div`
    position: absolute;
    top: 0;
    left: 50%;
    width: 170px;
    transform: translate(-10%, -50%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    transform: translate(0%, -100%);
    `};
    padding: 8px;
    &.red {
        color: #fff;
        background-color: red;
    }

    &.green {
        color: #fff;
        background: green;
    }

    font-size: 14px;
    font-weight: 500;

    border-radius: 20px 20px 20px 0;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    display:none;
    `};
`;

function HomepageHeader() {
    const history = useHistory();
    const [headerBgShow, setHeaderBgShow] = useState(false);
    useEffect(() => {
        const dom: Element | null = document.querySelector('.container');
        const scroll = () => {
            if (dom) {
                if (dom.scrollTop >= 40) {
                    setHeaderBgShow(true);
                } else {
                    setHeaderBgShow(false);
                }
            }
        };
        if (dom) {
            dom.addEventListener('scroll', scroll);
        }

        return () => {
            if (dom) {
                dom.removeEventListener('scroll', scroll);
            }
        };
    }, []);
    return (
        <HeaderWrapper className={classNames(headerBgShow && 'showBg')}>
            <Logo src={LogoIconImage} />

            <RightContainer>
                <a
                    className="header-link"
                    href="https://drive.google.com/file/d/1Oy9tiT4b4r4pZJhI_6t6i-6p6Q052M0E/view"
                    target="_blank"
                    rel="noreferrer"
                >
                    Litepaper
                </a>

                <NavLink className="header-link" to="/fantasy">
                    Fantasy
                </NavLink>

                <a
                    className="header-link"
                    href="https://docs.enkixyz.com/"
                    target="_blank"
                    rel="noreferrer"
                >
                    Docs
                </a>

                <Button
                    type={!headerBgShow ? 'outline' : 'outlineWhite'}
                    style={{ fontSize: '18px', position: 'relative' }}
                    onClick={() => {
                        history.push('/mint');
                    }}
                >
                    Stake Metis
                    {application.preStaking && (
                        <BoostTooltips className={'red'}>
                            Earn 2x Staking Points
                        </BoostTooltips>
                    )}
                </Button>
            </RightContainer>
        </HeaderWrapper>
    );
}
export default observer(HomepageHeader);
