/* eslint-disable react-hooks/exhaustive-deps */
import BigNumber from 'bignumber.js';
import { useEffect, useMemo, useState } from 'react';
import ERC20_ABI from 'contracts/erc20.json';
import { ethers } from 'ethers';
import useSend from './useSend';
import user from 'stores/user';
import { useContract } from './useContract';

export default function useTokenApprove(
    token: {
        symbol: string;
        address: string;
        decimals?: number;
    },
    spender: string,
    amount: BigNumber,
    interval?: number,
) {
    const { symbol, address, decimals } = token;
    const tokenContract = useContract(address, ERC20_ABI);
    const [allowance, setAllowance] = useState(new BigNumber(0));
    const { address: account } = user;
    const send = useSend();

    async function getAllowance() {
        try {
            if (tokenContract && account && spender) {
                let decimalsRes = decimals;
                if (!decimalsRes) {
                    decimalsRes = await tokenContract.decimals();
                }
                const allowanceRes = await tokenContract.allowance(
                    account,
                    spender,
                );

                if (allowance?.toString() && decimalsRes) {
                    setAllowance(
                        new BigNumber(allowanceRes.toString()).shiftedBy(
                            -decimalsRes,
                        ),
                    );
                } else {
                    setAllowance(new BigNumber(0));
                }
            }
        } catch (e) {
            console.error(
                'get allowance error',
                account,
                tokenContract?.address,
            );
            console.error(e);
        }
    }

    const approved = useMemo(() => {
        return amount.isLessThanOrEqualTo(allowance);
    }, [amount, allowance]);

    useEffect(() => {
        getAllowance();
        let i: any;
        if (!approved && interval) {
            i = setInterval(() => {
                getAllowance();
            }, interval);
        }

        return () => {
            if (i && interval) {
                clearInterval(interval);
            }
        };
    }, [tokenContract, account, approved]);

    async function approve() {
        const tokenBalanceRes = await tokenContract?.balanceOf(user.address);
        send({
            contract: tokenContract,
            method: 'approve',
            params: [
                spender,
                tokenBalanceRes
                    ? tokenBalanceRes.toString()
                    : ethers.constants.MaxUint256.toString(),
            ],
            pendingText: 'Approving',
            summary: `Approved ${symbol}`,
            onSuccess() {
                getAllowance();
            },
        });
    }

    return {
        approved,
        approve,
    };
}
