import axios from 'axios';
import { ChainId } from 'constant';
import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import storage from 'utils/storage';

const enkiParnersKey = 'enki-partnets';

const enkiPartnets = storage.get(enkiParnersKey) || [];

export function useGetParners() {
    const [partners, setENKIPartners] = useState<
        {
            img: string;
        }[]
    >(enkiPartnets);

    async function getPartners() {
        try {
            const json = await axios(
                `https://raw.githubusercontent.com/ENKIXYZ/resources/main/enki-web/partners/index.json`,
            );

            if (json.data) {
                setENKIPartners(json.data);

                storage.set(enkiParnersKey, json.data);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getPartners();
    }, []);

    return {
        partners,
    };
}
