import { Token } from 'constant';
import { useMemo } from 'react';
import { getTokenImg } from 'utils';
import { useGetContractByChainId } from './useContract';
import ERC20_ABI from 'contracts/erc20.json';
import user from 'stores/user';

export interface TokenParams {
    address: string;
    symbol: string;
    decimals: number;
    logoURI?: string;
    type?: 'ERC20' | 'ERC721';
    tokenId?: string;
    name: string;
}
export function useAddToken(
    token?: TokenParams,
): [boolean, () => Promise<void>] {
    const getContractByChainId = useGetContractByChainId();
    const tokenType = token?.type || 'ERC20';
    const canAdd = !!user.address;

    async function addToken() {
        if (!token) return;
        const img = (token as any)?.logoURI || getTokenImg(token as Token);
        let decimals = 0;
        if (tokenType === 'ERC20') {
            const contract = getContractByChainId(token.address, ERC20_ABI);
            if (contract) {
                decimals = await contract.decimals();
            }
        }

        if (token.address) {
            const params: any = {
                type: tokenType,
                options:
                    tokenType === 'ERC20'
                        ? {
                              address: token.address,
                              symbol: token.symbol,
                              decimals: decimals,
                              image: img,
                          }
                        : {
                              address: token.address,
                              symbol: token.symbol,
                              image: img,
                              tokenId: token.tokenId,
                          },
            };
            try {
                const success = await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params,
                });
                if (success) {
                    console.log(`Adding ${token.symbol}`);
                } else {
                    console.error('Add token faild', success);
                }
            } catch (e) {
                console.error(e);
            }
        } else {
            console.error(`token ${token.symbol} address or decimals error`);
        }
    }
    return [canAdd, addToken];
}
