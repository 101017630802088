import { metisSepolia } from './metisSepoliachain';
import { MainnetOnly } from 'constant';
export const projectId = '5bb516889b287ed82faf82964a564bd9';
import { getDefaultConfig, getDefaultWallets } from '@rainbow-me/rainbowkit';
import {
    argentWallet,
    imTokenWallet,
    ledgerWallet,
    okxWallet,
    oneKeyWallet,
    trustWallet,
    uniswapWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { andromeda } from './metisChain';

const { wallets: defaultWallets } = getDefaultWallets();
const wallets = [
    ...defaultWallets,
    {
        groupName: 'Other',
        wallets: [
            argentWallet,
            trustWallet,
            ledgerWallet,
            okxWallet,
            imTokenWallet,
            uniswapWallet,
            oneKeyWallet,
        ],
    },
];

const chainConfig = MainnetOnly
    ? {
          chains: [andromeda],
          appName: 'ENKI Protocol',
          projectId: projectId,
          wallets,
      }
    : {
          chains: [metisSepolia, andromeda],
          appName: 'ENKI Protocol',
          projectId: projectId,
          wallets,
      };

export default getDefaultConfig(chainConfig as any);
