import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import metisLogoImg from 'assets/images/home/logo_metis.png';
import netswapLogoImg from 'assets/images/home/logo_Netswap.png';
import herculesLogoImg from 'assets/images/home/logo_Hercules.png';
import hummusLogoImg from 'assets/images/home/logo_Hummus.png';
import { useGetParners } from 'hooks/useGetPartners';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 88px;
    margin: 0 auto;
    margin-top: 60px;
    width: 1200px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 20px);
    `};
`;

const LogoListWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
    width: 100%;
    flex-wrap: wrap;
    gap: 20px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: row;
    gap: 10px;
    margin-top: 20px;
    `};
`;

const LogoItem = styled.div`
    height: 100px;
    width: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    border: 3px solid rgba(228, 198, 3, 0.5);
    margin: 0 15px;
    flex-shrink: 0;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40%;
    height:62px;
    margin: 0;
    `};

    .logo {
        width: 100%;
    }
`;

export default function Parteners() {
    const { partners } = useGetParners();
    return (
        <Wrapper>
            <TYPE.main
                color="white01"
                fontSize={40}
                fontWeight="bold"
                textAlign="center"
                mobileFz={28}
            >
                Partners & Backers
            </TYPE.main>
            <TYPE.main
                color="white01"
                fontSize={16}
                textAlign="center"
                marginTop={12}
            >
                We partner with top protocols and backed by Metis, see below
            </TYPE.main>

            <LogoListWrapper>
                {partners?.map((item, index) => (
                    <LogoItem key={index}>
                        <img
                            className="logo"
                            src={`https://raw.githubusercontent.com/ENKIXYZ/resources/main/enki-web/partners/${item.img}`}
                        />
                    </LogoItem>
                ))}
            </LogoListWrapper>

            <TYPE.main
                color="mainColor"
                fontSize={16}
                textAlign={'center'}
                marginTop={48}
            >
                More coming soon…
            </TYPE.main>
        </Wrapper>
    );
}
