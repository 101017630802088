import { defineChain } from 'viem';

export const andromeda = defineChain({
    id: 1088,
    name: 'Metis Andromeda',
    nativeCurrency: {
        decimals: 18,
        name: 'Metis Andromeda',
        symbol: 'METIS',
    },
    rpcUrls: {
        default: {
            http: ['https://andromeda-rpc.metis.io'],
            webSocket: ['https://andromeda-rpc.metis.io'],
        },
        public: {
            http: ['https://andromeda-rpc.metis.io'],
            webSocket: ['https://andromeda-rpc.metis.io'],
        },
    },
    blockExplorers: {
        default: {
            name: 'Andromeda Explorer',
            url: 'https://andromeda-explorer.metis.io',
            apiUrl: 'https://andromeda-explorer.metis.io/api',
        },
    },
    contracts: {
        multicall3: {
            address: '0xca11bde05977b3631167028862be2a173976ca11',
            blockCreated: 2338552,
        },
    },
});
