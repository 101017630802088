import axios from 'axios';

import { useEffect, useState } from 'react';

import storage from 'utils/storage';

interface DefiData {
    protocol: string;
    chain: string;
    logo: string;
    assets: string;
    boost: string;
    boostInfo: string;
    stakingPoints: string;
    link: string;
    isActive: true;
}

const DefiListKey = 'enki-defi-list';

const data: DefiData[] = storage.get(DefiListKey) || [];

export function useGetDefiList() {
    const [defi, setDefi] = useState(data);
    const [loading, setLoading] = useState(false);
    const [fetchFailed, setFetchFailed] = useState(false);

    async function getDefi() {
        try {
            setLoading(true);
            const json = await axios(
                `https://raw.githubusercontent.com/ENKIXYZ/resources/main/enki-web/defi-page/list.json`,
            );

            if (json.data) {
                setDefi(json.data);

                storage.set(DefiListKey, json.data);
            }
        } catch (e) {
            console.error(e);
            setFetchFailed(true);
        }
        setLoading(false);
    }

    useEffect(() => {
        getDefi();
    }, []);

    return {
        defi,
        loading,
        fetchFailed,
    };
}
