import React, { useEffect, useMemo, useState } from 'react';
import { TYPE } from 'theme';
import { RowBetween } from 'components/Row';
import Button from 'components/Page/StageButton';
import { AutoColumn } from 'components/Column';

import TooltipInfo from 'components/TooltipInfo';
import BigNumber from 'bignumber.js';

import { useConstantToken } from 'hooks/useConstantToken';
import { useSeMetisVault } from 'hooks/useContract';
import useTokenApprove from 'hooks/useTokenApprove';
import useSend from 'hooks/useSend';
import user from 'stores/user';
import { observer } from 'mobx-react';

import useSeMetisInfo from 'hooks/useSeMetisInfo';
import useTokenBalance from 'hooks/useTokenBalance';
import { useGetEstimatedGas } from 'hooks/useEstimateGas';
import Numeral from 'numeral';

import useExploreLink from 'hooks/useExploreLink';

import useSequencerInfo from 'hooks/useSequencerInfo';

import PageContent, {
    ButtonGroup,
    ButtonRow,
    ComingSoonWrapper,
    LeftContent,
    RightContent,
    RightContentTitle,
    TransactionDetail,
    TransactionText,
    TransactionTokenLogo,
} from 'components/Page/PageContent';
import {
    ContentTitle,
    DataColumn,
    DataDesc,
    DataLink,
    DataTitle,
} from 'components/Page/DataComponents';
import PageWrapper from 'components/Page/PageWrapper';
import DataWrapper from 'components/Page/DataWrapper';
import SwitchTokenInput from 'components/TokenInput/SwitchTokenInput';
import { formatNumber } from 'utils';
import PreStakingTooltips from 'components/Page/PreStakingTooltips';
import { isMobile } from 'react-device-detect';
import application from 'stores/application';

function Stake() {
    const { eMetis, seMetis } = useConstantToken();
    const { balance: seMetisBalance, getBalance: getseMetisBalance } =
        useTokenBalance(seMetis.address, user.address);
    const { balance: eMetisBalance, getBalance: getEMetisBalance } =
        useTokenBalance(eMetis.address, user.address);
    const [eMetisInputTokenAmount, setEMetisInputTokenAmount] = useState(
        new BigNumber(0),
    );
    const [seMetisInputTokenAmount, setSeMetisInputTokenAmount] = useState(
        new BigNumber(0),
    );
    const [isStake, setIsStake] = useState(true);
    const seMetisVaultContract = useSeMetisVault();
    const { getAddressUrl } = useExploreLink();
    const [eMetisInputTokenValid, setEMetisInputValid] = useState(false);
    const [seMetisInputTokenValid, setSeMetisInputValid] = useState(false);
    const { exchangeRate, totalSupply: seMetisTotalSupply } = useSeMetisInfo();

    const { sequencerInfo, getSequencerInfoFunc } = useSequencerInfo();

    const send = useSend();

    const {
        approved: eMetisApprovedSeMetisVault,
        approve: eMetisApproveSeMetisVault,
    } = useTokenApprove(
        eMetis,
        seMetisVaultContract?.address || '',
        eMetisInputTokenAmount,
    );
    const {
        approved: seMetisApprovedSeMetisVault,
        approve: seMetisApproveSeMetisVault,
    } = useTokenApprove(
        seMetis,
        seMetisVaultContract?.address || '',
        seMetisInputTokenAmount,
    );

    async function onSeMetisVaultTransSuccess() {
        if (isStake) {
            setEMetisInputTokenAmount(new BigNumber(0));
        } else {
            setSeMetisInputTokenAmount(new BigNumber(0));
        }

        await getSequencerInfoFunc();
        await getEMetisBalance();
        await getseMetisBalance();
    }

    const estimatedGasStake = useGetEstimatedGas({
        contract: seMetisVaultContract,
        method: 'mint',
        params: [
            eMetisInputTokenAmount.shiftedBy(eMetis.decimals).toFixed(0),
            user.address,
        ],
        show: eMetisInputTokenAmount.isGreaterThan(0),
        fetch:
            eMetisApprovedSeMetisVault &&
            eMetisInputTokenAmount.isGreaterThan(0),
    });

    async function stakeEMetis() {
        if (!seMetisVaultContract) return;

        await send({
            contract: seMetisVaultContract,
            method: 'deposit',
            params: [
                eMetisInputTokenAmount.shiftedBy(eMetis.decimals).toFixed(0),
                user.address,
            ],
            pendingText: `Staking eMetis`,
            summary: `Stake ${eMetisInputTokenAmount.toFixed()} eMetis`,
            onSuccess: onSeMetisVaultTransSuccess,
        });
    }

    const estimatedGasUnstake = useGetEstimatedGas({
        contract: seMetisVaultContract,
        method: 'redeem',
        params: [
            seMetisInputTokenAmount.shiftedBy(seMetis.decimals).toFixed(0),
            user.address,
            user.address,
        ],
        show: seMetisInputTokenAmount.isGreaterThan(0),
        fetch:
            seMetisApprovedSeMetisVault &&
            seMetisInputTokenAmount.isGreaterThan(0),
    });

    async function unstakeEMetis() {
        if (!seMetisVaultContract) return;

        await send({
            contract: seMetisVaultContract,
            method: 'redeem',
            params: [
                seMetisInputTokenAmount.shiftedBy(seMetis.decimals).toFixed(0),
                user.address,
                user.address,
            ],
            pendingText: `Withdrawing eMetis`,
            summary: `Withdraw eMetis`,
            onSuccess: onSeMetisVaultTransSuccess,
        });
    }

    const canClickActionButton = useMemo(() => {
        return isStake
            ? eMetisInputTokenAmount.isGreaterThan(0) && eMetisInputTokenValid
            : seMetisInputTokenAmount.isGreaterThan(0) &&
                  seMetisInputTokenValid;
    }, [
        eMetisInputTokenValid,
        seMetisInputTokenValid,
        isStake,
        eMetisInputTokenAmount,
        seMetisInputTokenAmount,
    ]);

    const shareOfPool = useMemo(() => {
        if (seMetisTotalSupply.isEqualTo(0)) {
            return '0%';
        }

        return `${formatNumber(
            seMetisBalance.dividedBy(seMetisTotalSupply).multipliedBy(100),
        )}%`;
    }, [seMetisBalance, seMetisTotalSupply]);

    useEffect(() => {
        if (application.preStaking) {
            application.setIsPreStakingModalOpen(true);
        }

        return () => {
            application.setIsPreStakingModalOpen(false);
        };
    }, [application.preStaking]);

    return (
        <PageWrapper>
            {application?.preStaking && <ComingSoonWrapper />}
            <DataWrapper>
                <DataColumn>
                    <DataTitle>
                        {Numeral(seMetisTotalSupply).format('0,0.[0000]')}{' '}
                        seMetis
                    </DataTitle>
                    <DataDesc>seMetis SUPPLY</DataDesc>
                </DataColumn>
                <DataColumn>
                    <DataTitle>
                        {sequencerInfo?.apr.toFixed(2) || '-'}%
                    </DataTitle>
                    <DataDesc>ESTIMATED seMetis APR</DataDesc>
                </DataColumn>

                <DataColumn leftBorder={!isMobile}>
                    <DataTitle>CONTRACTS</DataTitle>
                    <DataLink href={getAddressUrl(eMetis.address)}>
                        <DataDesc>eMetis</DataDesc>
                    </DataLink>
                    <DataLink href={getAddressUrl(seMetis?.address || '')}>
                        <DataDesc>seMetis Vault</DataDesc>
                    </DataLink>
                </DataColumn>
            </DataWrapper>

            <ContentTitle>
                {isStake ? 'Stake eMetis' : 'Unstake seMetis'}
            </ContentTitle>
            <PageContent>
                <LeftContent>
                    <SwitchTokenInput
                        value={
                            isStake
                                ? eMetisInputTokenAmount
                                : seMetisInputTokenAmount
                        }
                        onChange={
                            isStake
                                ? setEMetisInputTokenAmount
                                : setSeMetisInputTokenAmount
                        }
                        inputPercent={[50]}
                        token={isStake ? eMetis : seMetis}
                        dToken={isStake ? seMetis : eMetis}
                        handleSwitchClick={() => {
                            setIsStake(!isStake);
                        }}
                        onInputValid={
                            isStake ? setEMetisInputValid : setSeMetisInputValid
                        }
                        exchangeRate={isStake ? exchangeRate : 1 / exchangeRate}
                    />
                    <RowBetween>
                        <TYPE.main fontSize={14}>SWAP FEE | 0.00%</TYPE.main>
                        <TYPE.main fontSize={14}>
                            (0.00 {isStake ? 'eMetis' : 'seMetis'})
                        </TYPE.main>
                    </RowBetween>
                    {isStake ? (
                        <TransactionDetail>
                            <RowBetween>
                                <TransactionText>MIN. RECEIVED</TransactionText>

                                <TransactionText>
                                    <TransactionTokenLogo src={seMetis.img} />
                                    {eMetisInputTokenAmount
                                        .multipliedBy(exchangeRate)
                                        .toFixed(4)}{' '}
                                    seMetis
                                </TransactionText>
                            </RowBetween>
                        </TransactionDetail>
                    ) : (
                        <TransactionDetail>
                            <RowBetween>
                                <TransactionText>
                                    EST. TOTAL CONVERTED (100%)
                                </TransactionText>

                                <TransactionText>
                                    <TransactionTokenLogo src={eMetis.img} />
                                    {!exchangeRate
                                        ? '-'
                                        : seMetisInputTokenAmount
                                              .dividedBy(exchangeRate)
                                              .toFixed(4)}{' '}
                                    eMetis
                                </TransactionText>
                            </RowBetween>
                            <RowBetween>
                                <TransactionText>
                                    EST. RECEIVED (70%)
                                </TransactionText>

                                <TransactionText>
                                    <TransactionTokenLogo src={eMetis.img} />
                                    {!exchangeRate
                                        ? '-'
                                        : seMetisInputTokenAmount
                                              .dividedBy(exchangeRate)
                                              .multipliedBy(0.7)
                                              .toFixed(4)}{' '}
                                    eMetis
                                </TransactionText>
                            </RowBetween>
                            <RowBetween>
                                <TransactionText>
                                    EST. SENDING TO VEST (30%)
                                </TransactionText>

                                <TransactionText>
                                    <TransactionTokenLogo src={eMetis.img} />
                                    {!exchangeRate
                                        ? '-'
                                        : seMetisInputTokenAmount
                                              .dividedBy(exchangeRate)
                                              .multipliedBy(0.3)
                                              .toFixed(4)}{' '}
                                    eMetis
                                </TransactionText>
                            </RowBetween>
                        </TransactionDetail>
                    )}
                    <ButtonGroup>
                        <ButtonRow>
                            {((isStake && !eMetisApprovedSeMetisVault) ||
                                (!isStake && !seMetisApprovedSeMetisVault)) &&
                                user.connected && (
                                    <Button
                                        preStaking
                                        disabled={!canClickActionButton}
                                        onClick={
                                            isStake
                                                ? eMetisApproveSeMetisVault
                                                : seMetisApproveSeMetisVault
                                        }
                                    >
                                        Approve
                                    </Button>
                                )}
                            {isStake ? (
                                <Button
                                    auth
                                    preStaking
                                    disabled={
                                        !eMetisApprovedSeMetisVault ||
                                        !canClickActionButton
                                    }
                                    onClick={stakeEMetis}
                                >
                                    Stake
                                </Button>
                            ) : (
                                <Button
                                    auth
                                    preStaking
                                    disabled={
                                        !seMetisApprovedSeMetisVault ||
                                        !canClickActionButton
                                    }
                                    onClick={unstakeEMetis}
                                >
                                    Unstake
                                </Button>
                            )}
                        </ButtonRow>
                    </ButtonGroup>
                </LeftContent>
                <RightContent>
                    <AutoColumn gap="48px">
                        <RightContentTitle>Current Stake</RightContentTitle>
                        <AutoColumn gap="32px">
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(seMetisBalance)} seMetis
                                </DataTitle>
                                <DataTitle>
                                    (
                                    {!exchangeRate
                                        ? '-'
                                        : formatNumber(
                                              seMetisBalance.dividedBy(
                                                  exchangeRate,
                                              ),
                                          )}{' '}
                                    eMetis)
                                </DataTitle>
                                <DataDesc>Your Staked Amount</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {formatNumber(eMetisBalance)} eMetis
                                </DataTitle>
                                <DataDesc>Your eMetis Balance</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>{shareOfPool}</DataTitle>
                                <DataDesc>Your share of the pool</DataDesc>
                            </DataColumn>
                            <DataColumn>
                                <DataTitle>
                                    {exchangeRate
                                        ? (1 / exchangeRate).toFixed(4)
                                        : '-'}
                                </DataTitle>
                                <DataDesc>eMetis per seMetis</DataDesc>
                            </DataColumn>
                            <DataLink href="https://docs.enkixyz.com/guides/stake-unstake">
                                <DataDesc>Documentation</DataDesc>
                            </DataLink>
                        </AutoColumn>
                    </AutoColumn>
                </RightContent>
            </PageContent>
        </PageWrapper>
    );
}
export default observer(Stake);
