import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import stakeBgImg from 'assets/images/home/stake_bg.png';
import stakeItem01Img from 'assets/images/home/stake_01.png';
import stakeItem02Img from 'assets/images/home/stake_02.png';
import stakeItem03Img from 'assets/images/home/stake_03.png';
import stakeItem04Img from 'assets/images/home/stake_04.png';

const Wrapper = styled.div`
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    background: url(${stakeBgImg}) no-repeat;
    background-position: center 10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    align-items: center;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding-bottom: 88px;
    `};
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 60px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction: column;
    width: calc(100% - 20px);
    gap: 20px;
    margin-top: 20px;
    `};
`;

const Panel = styled.div`
    display: flex;
    background: linear-gradient(
        225deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.3) 100%
    );
    border-radius: 40px;
    width: 460px;
    height: 200px;
    padding: 40px 10px 20px 42px;
    margin: 0 35px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    margin: 0;
    height: fit-content;
    padding: 20px;
    `};

    .itemIc {
        height: 100px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        height: 50px;
        `};
    }
`;

const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 24px;
`;

export default function Stake() {
    return (
        <Wrapper>
            <TYPE.main
                color="white01"
                fontSize={40}
                fontWeight="bold"
                textAlign="center"
                mobileFz={28}
            >
                Why Stake with ENKI?
            </TYPE.main>
            <TYPE.main
                color="white01"
                fontSize={16}
                textAlign="center"
                marginTop="12"
            >
                Maximize your staking rewards while maintaining composibility
                for DeFi protocols
            </TYPE.main>

            <Row>
                <Panel>
                    <img className="itemIc" src={stakeItem01Img} alt="" />
                    <TextWrapper>
                        <TYPE.main
                            fontSize={16}
                            color="white01"
                            fontWeight="bold"
                        >
                            Universal Access
                        </TYPE.main>
                        <TYPE.main
                            fontSize={14}
                            color="mainColor"
                            marginTop={14}
                        >
                            Open to all, ENKI simplifies staking participation,
                            making rewarding opportunities accessible
                        </TYPE.main>
                    </TextWrapper>
                </Panel>

                <Panel>
                    <img className="itemIc" src={stakeItem02Img} alt="" />
                    <TextWrapper>
                        <TYPE.main
                            fontSize={16}
                            color="white01"
                            fontWeight="bold"
                        >
                            Hassle-Free Node Management
                        </TYPE.main>
                        <TYPE.main
                            fontSize={14}
                            color="mainColor"
                            marginTop={14}
                        >
                            We manage the complexities, allowing you to enjoy
                            seamless staking without the technical hassle
                        </TYPE.main>
                    </TextWrapper>
                </Panel>
            </Row>

            <Row>
                <Panel>
                    <img className="itemIc" src={stakeItem03Img} alt="" />
                    <TextWrapper>
                        <TYPE.main
                            fontSize={16}
                            color="white01"
                            fontWeight="bold"
                        >
                            Rigorous Security Audits
                        </TYPE.main>
                        <TYPE.main
                            fontSize={14}
                            color="mainColor"
                            marginTop={14}
                        >
                            Security is our top priority, with thorough audits
                            ensuring a safe staking environment
                        </TYPE.main>
                    </TextWrapper>
                </Panel>

                <Panel>
                    <img className="itemIc" src={stakeItem04Img} alt="" />
                    <TextWrapper>
                        <TYPE.main
                            fontSize={16}
                            color="white01"
                            fontWeight="bold"
                        >
                            Flourishing Ecosystem
                        </TYPE.main>
                        <TYPE.main
                            fontSize={14}
                            color="mainColor"
                            marginTop={14}
                        >
                            eMetis and seMetis are intended to be used in DeFi.
                            ENKI is partnering with a wide array of DeFi
                            protocols to increase utility of eMetis and seMetis
                        </TYPE.main>
                    </TextWrapper>
                </Panel>
            </Row>
        </Wrapper>
    );
}
