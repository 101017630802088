import React from 'react';
import cx from 'classnames';
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Zoom from '@mui/material/Zoom';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const ModalWrapper = styled(Modal)`
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Content = styled.div`
    position: relative;
    min-width: 480px;
    max-width: 800px;
    padding: 40px 38px;
    outline: none;
    box-sizing: border-box;

    background: linear-gradient(180deg, #fafafa 0%, #5a382f 0%, #c77353 100%);
    border-radius: 24px;
    max-height: 96vh;
    overflow-y: scroll;
    ::-webkit-scrollbar {
        width: 0;
    }
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 2rem);
    min-width:80vw;
    max-width: calc(100vw - 2rem);
    padding: 1rem;
    `}
`;
const CloseIconWrapper = styled.div`
    position: absolute;
    right: 32px;
    top: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    z-index: 1;
    border-radius: 12px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    right: 1rem;
    top: 1rem;
    `}
`;

const StyledCloseIcon = styled.img`
    color: #000;
    cursor: pointer;
    width: 24px !important;
    height: 24px !important;
`;

export interface Props {
    id?: string;
    isOpen?: boolean;
    onClose?(): void;
    children?: React.ReactNode;
    customContentClass?: string;
    hideClose?: boolean;
    disableBackgroundClick?: boolean;
    keepMounted?: boolean;
    className?: string;
}

export default function TransitionsModal({
    id,
    isOpen,
    onClose,
    children,
    customContentClass,
    hideClose,
    keepMounted,
    disableBackgroundClick = false,
    className,
}: Props) {
    return (
        <ModalWrapper
            id={id}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={cx(className)}
            open={!!isOpen}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            keepMounted={keepMounted}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Zoom in={isOpen}>
                <Content className={cx('modalContent', customContentClass)}>
                    {!hideClose && (
                        <CloseIconWrapper className="closeIcon">
                            <StyledCloseIcon
                                src={require('assets/images/ic_close.png')}
                                onClick={onClose}
                            />
                        </CloseIconWrapper>
                    )}
                    {children}
                </Content>
            </Zoom>
        </ModalWrapper>
    );
}
