import React, { ReactNode, CSSProperties, ReactElement, useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Tooltip from '@mui/material/Tooltip';
import { observer } from 'mobx-react';
import application from 'stores/application';
import { isMobile } from 'react-device-detect';
interface Props {
    style?: CSSProperties;
    placement?: 'top' | 'bottom' | 'top-start' | 'left' | 'right';
    children?: ReactNode;
}

const TooltipsWrapper = styled(Tooltip)``;
const Wrapper = styled.div`
    width: fit-content;
`;

const GStyle = createGlobalStyle`
    #preStakingTooltips {
        z-index: 1;
        > .MuiTooltip-tooltip {
            padding: 12px;
            font-size: 12px;;
            background: red;
            max-width: unset;
            .MuiTooltip-arrow  {
                color: red;
            }
        }
    }
`;

function PreStakingTooltips({
    style: cssStyle,
    placement = 'right',
    children,
}: Props) {
    return (
        <>
            <TooltipsWrapper
                title="Earn double staking points at pre-staking stage."
                placement={placement}
                arrow
                open={!isMobile && application.preStaking}
                id="preStakingTooltips"
            >
                <Wrapper
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        ...cssStyle,
                    }}
                >
                    {children}
                </Wrapper>
            </TooltipsWrapper>
            <GStyle />
        </>
    );
}

export default observer(PreStakingTooltips);
