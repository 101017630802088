import { useEffect } from 'react';
import { watchAccount, GetAccountReturnType } from '@wagmi/core';
import config from 'common-helpers/web3modalconfig';

export default function useWatchAccountChange({
    onChange,
}: {
    onChange(
        account?: GetAccountReturnType,
        prevAccount?: GetAccountReturnType,
    ): void;
}) {
    useEffect(() => {
        const unwatch = watchAccount(config, {
            onChange: (account, prevAccount) => {
                if (account.address !== prevAccount.address) {
                    onChange(account, prevAccount);
                }
            },
        });

        return () => {
            unwatch();
        };
    }, []);
}
