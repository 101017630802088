import { useEMetis } from './useContract';
import { useEffect, useState } from 'react';
import BigNumber from 'bignumber.js';
import { useConstantToken } from './useConstantToken';

export default function useEMetisInfo() {
    const { eMetis, seMetis } = useConstantToken();
    const eMetisContract = useEMetis();
    const [totalSupply, setTotalSupply] = useState(new BigNumber(0));
    const [totalStaked, setTotalStaked] = useState(new BigNumber(0));

    async function getTotalSupply() {
        if (!eMetisContract) return;

        try {
            const res = await eMetisContract.totalSupply();

            if (res) {
                setTotalSupply(
                    new BigNumber(res?.toString()).shiftedBy(-eMetis.decimals),
                );
            }
        } catch (e) {
            console.error('get eMetis totalSupply error');
            console.error(e);
        }
    }

    async function getSeMetisTotalStaked() {
        if (!eMetisContract) return;

        try {
            const res = await eMetisContract.balanceOf(seMetis.address);

            if (res) {
                setTotalStaked(
                    new BigNumber(res?.toString()).shiftedBy(-eMetis.decimals),
                );
            }
        } catch (e) {
            console.error('get eMetis balance error');
            console.error(e);
        }
    }

    useEffect(() => {
        getTotalSupply();
        getSeMetisTotalStaked();
    }, [eMetisContract]);

    return {
        totalSupply,
        totalStaked,
        getTotalSupply,
    };
}
