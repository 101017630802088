import BigNumber from "bignumber.js";

const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
const base = 62;
const ADDRESS_LENGTH = 40;

export function compressAddress(address: string) {
    let bigStr = new BigNumber(address.substring(2).toLowerCase(), 16);
    // let bigStr = new BigNumber(testStr.substring(2).toLowerCase(), 16);
    const arr: string[] = [];

    do {
        const mod = bigStr.mod(base);
        arr.unshift(chars[mod.toNumber()]);
        bigStr = bigStr.minus(mod).dividedBy(base);
    } while (bigStr.gt(0));

    return arr.join('');
}

export function decompressString(str: string) {
    let bigStr = new BigNumber(0);
    for (let i = 0; i < str.length; i++) {
        bigStr = bigStr.multipliedBy(base).plus(chars.indexOf(str[i]));
    }
    let res = bigStr.toString(16);

    if (res.length < ADDRESS_LENGTH) {
        res = new Array(ADDRESS_LENGTH - res.length).fill('0').join('') + res;
    }

    return '0x' + res;
}
