import { Modal } from '@mui/material';
import Row, { RowBetween } from 'components/Row';
import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import { TokenParams, useAddToken } from 'hooks/useAddToken';
import BigNumber from 'bignumber.js';
import Button from 'components/Button';
import { OuterLinkSVG } from 'common-helpers/img';
import { AutoColumn } from 'components/Column';
const StyledModal = styled(Modal)``;

const Content = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 480px;
    border-radius: 24px;
    overflow: hidden;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 20px);
    `};
`;

const ImageWrapper = styled.div`
    width: 100%;
    position: relative;
    height: 300px;
    background: linear-gradient(180deg, #fafafa 0%, #5a382f 0%, #c77353 100%);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: auto;
        height: 282px;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    height: 230px;
    `};
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
    padding: 40px 80px;
    background: linear-gradient(180deg, #fafafa 0%, #5a382f 0%, #c77353 100%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 20px 40px;
    `};
`;

const Close = styled.img`
    position: absolute;
    right: 24px;
    top: 24px;
    width: 24px !important;
    height: 24px !important;
    cursor: pointer;
`;

const StyledButton = styled(Button)`
    /* width: 200px; */
    height: 64px !important;
    background: linear-gradient(270deg, #be692a 0%, #e8c074 100%) !important;
    border-radius: 42px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 0 20px !important;
`;

const LinkRow = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 100%;
    background: #a15e46;
    border-radius: 16px;
    padding: 20px 30px;
    justify-content: space-between;
`;

const OuterLink = styled.a`
    text-decoration: none;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
    color: #fff;
    img {
        width: 10px;
        height: 10px;
    }
`;

const StyledOuterLinkIcon = styled(OuterLinkSVG)`
    color: #fff;
    width: 10px;
    height: 10px;
`;

interface Props {
    open: boolean;
    onClose(): void;
    token: TokenParams;
    amount: BigNumber;
}
export default function ClaimENKISuccessModal({
    open,
    onClose,
    token,
    amount,
}: Props) {
    const [, addToken] = useAddToken(token);
    return (
        <StyledModal open={open} onClose={onClose}>
            <Content>
                <ImageWrapper>
                    <img src={require('./imgs/gold.png')} alt="" />
                    <Close
                        onClick={onClose}
                        src={require('./imgs/close.png')}
                    />
                </ImageWrapper>
                <TextWrapper>
                    <Row justify="center" gap="20px">
                        <img src={require('./imgs/enkitoken.png')} alt="" />
                        <TYPE.main
                            fontSize={32}
                            mobileFz={24}
                            fontWeight={600}
                            color="#fff"
                        >
                            {amount.toFixed(4)} ENKI
                        </TYPE.main>
                    </Row>
                    <TYPE.main fontSize={14} color="#fff" textAlign={'center'}>
                        Congratulations on successfully claiming{' '}
                        {amount.toFixed(4)} ENKI tokens!
                    </TYPE.main>

                    <Row justify="center">
                        <StyledButton onClick={addToken}>
                            Add ENKI to your wallet
                        </StyledButton>
                    </Row>
                    <LinkRow>
                        <AutoColumn gap="10px">
                            <OuterLink
                                href="https://netswap.io/#/swap?outputCurrency=0x096A84536ab84E68ee210561FFD3A038E79736F1"
                                target="_blank"
                            >
                                Get more ENKI
                                <StyledOuterLinkIcon />
                            </OuterLink>
                            <OuterLink
                                href="https://netswap.io/#/add/0x096a84536ab84e68ee210561ffd3a038e79736f1/0xdeaddeaddeaddeaddeaddeaddeaddeaddead0000"
                                target="_blank"
                            >
                                LP ENKI/Metis
                                <StyledOuterLinkIcon />
                            </OuterLink>
                        </AutoColumn>
                        <AutoColumn gap="10px">
                            <OuterLink
                                href="https://netswap.io/#/add/0x096a84536ab84e68ee210561ffd3a038e79736f1/0x90fE084F877C65e1b577c7b2eA64B8D8dd1AB278"
                                target="_blank"
                            >
                                LP ENKI/NETT
                                <StyledOuterLinkIcon />
                            </OuterLink>
                            <OuterLink
                                href="https://app.hercules.exchange/liquidity/?token1=0x096a84536ab84e68ee210561ffd3a038e79736f1&token2=0xEA32A96608495e54156Ae48931A7c20f0dcc1a21&type=v2&position=lp"
                                target="_blank"
                            >
                                LP ENKI/m.USDC
                                <StyledOuterLinkIcon />
                            </OuterLink>
                        </AutoColumn>
                    </LinkRow>
                </TextWrapper>
            </Content>
        </StyledModal>
    );
}
