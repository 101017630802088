import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 990px;
    margin: 0 auto;
    margin-top: 40px;
    position: relative;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100vw - 20px);
    margin-top: 10px;
    `};
`;
export default function PageWrapper({
    children,
}: {
    children: React.ReactNode;
}) {
    return <Wrapper>{children}</Wrapper>;
}
