import axios from 'axios';
import { ChainId } from 'constant';
import { useEffect, useMemo, useState } from 'react';
import user from 'stores/user';
import storage from 'utils/storage';

const eMetisMetisLPKey = 'enki-emetis-metis-lp';

const eMetisMetisLps = storage.get(eMetisMetisLPKey) || {
    [ChainId.mainnet]: [],
    [ChainId.testnet]: [],
};

export function useGetEMetisMetisLPConfig() {
    const [lpsRes, setLpsRes] = useState(eMetisMetisLps);
    const { chainId } = user;

    async function getLP() {
        try {
            const json = await axios(
                `https://raw.githubusercontent.com/ENKIXYZ/resources/main/enki-web/${chainId}/emetis-metis-lp.json`,
            );

            if (json.data) {
                (lpsRes as any)[chainId] = json.data ? [...json.data] : [];
                setLpsRes(lpsRes);

                storage.set(eMetisMetisLPKey, lpsRes);
            }
        } catch (e) {
            console.error(e);
        }
    }

    useEffect(() => {
        getLP();
    }, [chainId]);

    const lps: any[] = useMemo(() => {
        return lpsRes[chainId];
    }, [lpsRes, chainId]);

    return {
        lps,
    };
}
