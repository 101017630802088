import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
    width: 100%;
    height: 160px;
    background: linear-gradient(
        225deg,
        rgba(0, 0, 0, 0.3) 0%,
        rgba(0, 0, 0, 0.5) 100%,
        rgba(0, 0, 0, 0.3) 100%
    );
    border-radius: 32px;
    backdrop-filter: blur(2.4px);
    display: flex;
    align-items: center;
    margin: 0 auto;
    padding: 20px 70px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 10px;
    `};
    > div,
    a {
        flex: 1;
    }

    ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-wrap: wrap;
    height: auto;
    align-items: flex-start;
    justify-content: space-around;
    gap: 20px;
    > div, a {
        flex: unset;
    }
    `};
`;

export default Wrapper;

// interface Props {
//     children: React.ReactNode;
//     style?: React.CSSProperties;
// }
// export default function DataWrapper({ children, style }: Props) {
//     return <Wrapper style={style}>{children}</Wrapper>;
// }
