import React from 'react';
import Header from 'components/Header/homepage';
import styled, { createGlobalStyle } from 'styled-components';
import BannerBg from 'assets/images/home/bg_top.png';

import Banner from './banner';
import Overview from './overview';
import Rewards from './rewards';
import Parteners from './parteners';
import Stake from './stake';
import Audits from './audits';
import FantasyTips from 'components/FantasyTips';

const Wrapper = styled.div`
    width: 100%;
`;

const BannerWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 120px 0 95px 0;
    background: url(${BannerBg}) no-repeat;
    background-position: center;
    background-size: 100% 100%;
    position: relative;
    height: 768px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    background-size:cover;
    padding: 130px 0 30px 0;
    height: fit-content;
    `};
`;

const GlobalStyle = createGlobalStyle`
    .mainView {
        padding-top: 0 !important;
    }
`;

const StyledFantasyTips = styled(FantasyTips)`
    margin: 0 auto;

    /* ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 100px;
    `}; */
`;

export default function Home() {
    return (
        <Wrapper className="container">
            <GlobalStyle />
            <Header />
            <BannerWrapper>
                <StyledFantasyTips />
                <Banner />
            </BannerWrapper>

            <Overview />
            <Rewards />
            <Stake />
            <Parteners />
            <Audits />
        </Wrapper>
    );
}
