import Button from 'components/Button';
import { AutoColumn } from 'components/Column';
import Row from 'components/Row';
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import application from 'stores/application';
import user from 'stores/user';
import styled from 'styled-components';
import { TYPE } from 'theme';
import ShareModal from './shareModal';
import { formatPoints } from 'utils';

const Wrapper = styled(AutoColumn)`
    width: 900px;
    background: linear-gradient(90deg, #2c160a 0%, #150701 100%);
    border-radius: 24px;
    padding: 55px;
    gap: 40px;
    justify-items: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    padding: 30px 10px;
    `};

    a {
        color: ${({ theme }) => theme.c.mainColor};
    }
`;

const DashLine = styled.div`
    height: 2px;
    width: 100%;
    border-top: 1px dashed #dca45c;
`;

const CountDownWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
`;

const TimeGroup = styled(AutoColumn)`
    justify-items: center;
    gap: 16px;
`;

const TimeItem = styled.div`
    position: relative;
    width: 50px;
    background: #201f1f;
    border-radius: 8px;
    border: 1px solid #dca45c;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #dca45c;
    font-weight: bold;
    padding: 10px 10px;
    z-index: 1;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 40px;
    padding: 5px;
    font-size: 24px;

    `};
`;

const TimeLine = styled.div`
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #000;
    box-shadow: 0px 0px 16px #fff;
    z-index: -1;
`;

const Breaker = styled.div`
    font-size: 32px;
    font-weight: 600;
    color: #dca45c;
    transform: translate(0, -20px);
`;

const StakingPoints = styled.div`
    height: 70px;
    background: linear-gradient(
        270deg,
        rgba(220, 164, 92, 0) 0%,
        rgba(220, 164, 92, 0.5) 45%,
        rgba(220, 164, 92, 0.5) 55%,
        rgba(220, 164, 92, 0) 100%
    );
    padding: 16px 32px;
    font-size: 28px;
    font-weight: 600;
    color: #dca45c;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    font-size: 20px;
    padding: 10px;
    width: 100%;
    `};
`;

const BoostTooltips = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(100%, -100%);
    ${({ theme }) => theme.mediaWidth.upToSmall`
    transform: translate(30%, -100%);
    `};
    padding: 8px;
    &.red {
        color: #fff;
        background-color: red;
    }

    &.green {
        color: #fff;
        background: green;
    }

    font-size: 14px;
    font-weight: 500;

    border-radius: 20px 20px 20px 0;
`;

let leftTime = (application.preStakingEndDate - new Date().getTime()) / 1000;

function PreStaking() {
    const [isOpenClaimComingSoon, setIsOpenClaimComingSoon] = useState(false);
    const history = useHistory();
    const [times, setTimes] = useState([0, 0, 0, 0, 0, 0, 0, 0]);
    const [end, setEnd] = useState(leftTime <= 0);

    function setCountDown() {
        const days = Math.floor(leftTime / 86400);
        const hours = Math.floor((leftTime - days * 86400) / 3600);
        const minutes = Math.floor(
            (leftTime - days * 86400 - hours * 3600) / 60,
        );
        const seconds = Math.floor(leftTime % 60);

        leftTime -= 1;
        if (leftTime <= 0) {
            setEnd(true);
            return true;
        } else {
            setTimes([
                Math.floor(days / 10),
                days % 10,
                Math.floor(hours / 10),
                hours % 10,
                Math.floor(minutes / 10),
                minutes % 10,
                Math.floor(seconds / 10),
                seconds % 10,
            ]);
            return false;
        }
    }

    // useEffect(() => {
    //     setCountDown();
    //     const interval = setInterval(() => {
    //         const isEnd = setCountDown();
    //         if (isEnd) {
    //             clearInterval(interval);
    //         }
    //     }, 1000);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // }, [application.preStakingEndDate]);
    return (
        <Wrapper>
            <AutoColumn gap="10px">
                <TYPE.main
                    style={{ position: 'relative' }}
                    fontSize={32}
                    fontWeight={600}
                    textAlign={'center'}
                >
                    Metis Staking
                    <BoostTooltips
                        className={!application.preStaking ? 'green' : 'red'}
                    >
                        {!application.preStaking
                            ? 'Official Staking'
                            : 'Earn 2x Staking Points'}
                    </BoostTooltips>
                </TYPE.main>
                <TYPE.main color={'#DCA45C'} fontSize={18} textAlign={'center'}>
                    Sharing 400,000 ENKI
                </TYPE.main>
            </AutoColumn>
            {user.connected && (
                <StakingPoints style={{ textTransform: 'uppercase' }}>
                    Staking Point: {formatPoints(application.stakingPoints)}
                </StakingPoints>
            )}
            <DashLine />
            <TYPE.main color={'#DCA45C'} fontSize={16} textAlign={'center'}>
                Users who stake Metis within the ENKI protocol will share
                400,000 ENKI tokens based on their share of total staking
                points. Staking points explanation is&nbsp;
                <a
                    href="https://docs.enkixyz.com/getting-started/points-system/staking-points"
                    target="_blank"
                    rel="noreferrer"
                >
                    here
                </a>
                .
            </TYPE.main>

            {!end && (
                <>
                    {/* <TYPE.main
                        color={'#fff'}
                        fontSize={16}
                        textAlign={'center'}
                    >
                        Pre-staking stage ends in
                    </TYPE.main> */}
                    {/* <CountDownWrapper>
                        <TimeGroup>
                            <Row justify="center" gap="8px">
                                <TimeItem>
                                    {times[0]}
                                    <TimeLine />
                                </TimeItem>
                                <TimeItem>
                                    {times[1]}
                                    <TimeLine />
                                </TimeItem>
                            </Row>
                            <TYPE.main fontSize={14} color={'#DCA45C'}>
                                Days
                            </TYPE.main>
                        </TimeGroup>

                        <Breaker>:</Breaker>
                        <TimeGroup>
                            <Row justify="center" gap="8px">
                                <TimeItem>
                                    {times[2]}

                                    <TimeLine />
                                </TimeItem>
                                <TimeItem>
                                    {times[3]}

                                    <TimeLine />
                                </TimeItem>
                            </Row>
                            <TYPE.main fontSize={14} color={'#DCA45C'}>
                                Hours
                            </TYPE.main>
                        </TimeGroup>

                        <Breaker>:</Breaker>
                        <TimeGroup>
                            <Row justify="center" gap="8px">
                                <TimeItem>
                                    {times[4]}

                                    <TimeLine />
                                </TimeItem>
                                <TimeItem>
                                    {times[5]}

                                    <TimeLine />
                                </TimeItem>
                            </Row>
                            <TYPE.main fontSize={14} color={'#DCA45C'}>
                                Minutes
                            </TYPE.main>
                        </TimeGroup>

                        <Breaker>:</Breaker>
                        <TimeGroup>
                            <Row justify="center" gap="8px">
                                <TimeItem>
                                    {times[6]}

                                    <TimeLine />
                                </TimeItem>
                                <TimeItem>
                                    {times[7]}

                                    <TimeLine />
                                </TimeItem>
                            </Row>
                            <TYPE.main fontSize={14} color={'#DCA45C'}>
                                Seconds
                            </TYPE.main>
                        </TimeGroup>
                    </CountDownWrapper> */}
                </>
            )}
            <Button
                auth
                onClick={() => {
                    history.push('/mint');
                }}
            >
                Go to stake
            </Button>
            <ShareModal
                open={isOpenClaimComingSoon}
                img={require('../img/enki-banner.png')}
                text="Stay alert for the upcoming token claim phase."
                type="follow"
                onClose={() => {
                    setIsOpenClaimComingSoon(false);
                }}
            />
        </Wrapper>
    );
}

export default observer(PreStaking);
