import React from 'react';
import { observer } from 'mobx-react';
import { SimpleCDialog } from './index';
import simpleDialog from 'stores/simpleDialog';

function GlobalSimepleDialog() {
    const { type, title, text, confirm, isOpen, close, hideCancel } =
        simpleDialog;
    return (
        <SimpleCDialog
            open={isOpen}
            onClose={close}
            type={type}
            title={title}
            text={text}
            confirm={confirm}
            hideCancel={hideCancel || type === 'error'}
        />
    );
}

export default observer(GlobalSimepleDialog);
