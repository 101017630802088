import { observer } from 'mobx-react';
import React from 'react';
import Button, { Props as ButtonProps } from 'components/Button';
import application from 'stores/application';

interface Props extends ButtonProps {
    preStaking?: boolean;
}

function StageButton({ preStaking, ...props }: Props) {
    async function handleClick(e?: React.SyntheticEvent) {
        if (preStaking && application.preStaking) {
            application.setIsPreStakingModalOpen(true);
        } else {
            if (props.onClick) {
                await props.onClick(e);
            }
        }

        return;
    }
    return <Button {...props} onClick={handleClick} />;
}

export default observer(StageButton);
