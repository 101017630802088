import { useEffect } from 'react';
import { useAccount, useChainId, useWalletClient } from 'wagmi';
import { useDisconnect } from 'wagmi';
import Web3 from 'web3';
import { getSupportChainData } from 'common-helpers/utils';
import Modal from 'utils/components/modal';
import user from 'stores/user';
import { DefaultChainId, NETWORK_CONFIG, SupportChainIDs } from 'constant';
import { useChainModal } from '@rainbow-me/rainbowkit';

function initWeb3(provider: any) {
    const web3: any = new Web3(provider);
    web3.eth.extend({
        methods: [
            {
                name: 'chainId',
                call: 'eth_chainId',
                outputFormatter: web3.utils.hexToNumber,
            },
        ],
    });
    return web3;
}
export default function useWalletConnect() {
    const { chainId } = useAccount();
    const { address, isConnecting, isDisconnected, connector } = useAccount();
    const { openChainModal, chainModalOpen } = useChainModal();
    const { data: walletClient, isError } = useWalletClient();
    const { disconnect } = useDisconnect();
    function resetApp() {
        user.clearLoginInfo();
    }

    function detectIsCorrectNetwrok(chainId: number) {
        console.log(chainId);
        const chainData = getSupportChainData(chainId);
        if (!chainData) {
            // hook will be called many times, filter muti alert
            // if (!(window as any).alertChangeNetwork) {
            //     (window as any).alertChangeNetwork = Modal({
            //         type: 'error',
            //         text: `Please change to ${NETWORK_CONFIG[DefaultChainId].name} network`,
            //         buttonText: 'OK',
            //         onClose: async () => {
            //             (window as any).alertChangeNetwork = null;
            //             resetApp();
            //             disconnect();
            //         },
            //     });
            // }
            return false;
        } else if ((window as any).alertChangeNetwork) {
            (window as any).alertChangeNetwork();
            (window as any).alertChangeNetwork = null;
        }

        return true;
    }
    useEffect(() => {
        if (address && walletClient && !isDisconnected) {
            user.setAddress(address);
            user.setConnected(true);
            user.setWeb3(initWeb3(walletClient));
        } else {
            user.clearLoginInfo();
        }
    }, [address, isDisconnected, walletClient]);

    useEffect(() => {
        if (chainId && SupportChainIDs.indexOf(chainId) >= 0) {
            user.setChainId(chainId);
        }
    }, [chainId]);

    useEffect(() => {
        if (chainId && SupportChainIDs.indexOf(chainId) < 0 && openChainModal) {
            openChainModal();
        }
    }, [chainId]);

    return null;
}
