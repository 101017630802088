import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { TYPE } from 'theme';

const Wrapper = styled.div`
    width: calc(100% - 20px);
    max-width: 1000px;
    background: linear-gradient(90deg, #be692a 0%, #e8c074 100%), #c67a39;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    gap: 10px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: calc(100% - 20px);
    flex-direction: column;
    padding: 10px;
    gap: 10px;
    max-width: unset;
    `};
`;

const TextWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;

    img {
        width: 48px;
        height: 48px;
    }
`;

const Link = styled(NavLink)`
    height: 40px;
    background: #6d4125;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 24px;
    color: #fff;
    text-decoration: none;
    flex-shrink: 0;
`;

interface Props {
    className?: string;
}
export default function FantasyTips({ className }: Props) {
    return (
        <Wrapper className={className}>
            <TextWrapper>
                <img src={require('assets/images/logo-dark.png')} alt="" />
                <TYPE.main
                    fontSize={15}
                    textAlign={'center'}
                    color="#000"
                    wordBreak
                    flexShrink={1}
                    flexGrow={1}
                >
                    Fantasy page is live! Claim exclusive NFTs and ENKI tokens
                    on the page, ends on Jan 1, 2025
                </TYPE.main>
            </TextWrapper>
            <Link to="/fantasy">Check ENKI Eligibility</Link>
        </Wrapper>
    );
}
