import { useEffect, useMemo, useState } from 'react';
import { useVesting } from './useContract';
import BigNumber from 'bignumber.js';
import user from 'stores/user';
import { useConstantToken } from './useConstantToken';
import moment from 'moment';

export default function useVestingMethods() {
    const vestingContract = useVesting();
    const { enki, eMetis } = useConstantToken();
    const [claimableAmnount, setClaimAbleAmount] = useState(new BigNumber(0));
    const [claimedAmount, setClaimedAmount] = useState(new BigNumber(0));
    const [lastVestingTime, setLastVestingTime] = useState(0);
    const [estimateFinishedTime, setEstimateFinishedTime] = useState(0);
    const [cumulativeClaimAmount, setCumulativeClaimAmount] = useState(
        new BigNumber(0),
    );
    const [userStakeENKIBalance, setUserStakeENKIBalance] = useState(
        new BigNumber(0),
    );

    const [userLockEMetisBalance, setUserLockEMetisBalance] = useState(
        new BigNumber(0),
    );

    async function getUserInfo() {
        if (!vestingContract || !user.address) return;

        try {
            const userInfo = await vestingContract?.userInfos(user.address);

            if (userInfo?.balance) {
                setUserStakeENKIBalance(
                    new BigNumber(userInfo?.balance?.toString()).shiftedBy(
                        -enki.decimals,
                    ),
                );
            }

            if (userInfo?.cumulativeClaimAmount) {
                setCumulativeClaimAmount(
                    new BigNumber(
                        userInfo?.cumulativeClaimAmount?.toString(),
                    ).shiftedBy(-eMetis.decimals),
                );
            }

            if (userInfo?.lastVestingTime) {
                setLastVestingTime(userInfo?.lastVestingTime.toNumber() * 1000);
            }

            if (userInfo?.eMetisBalance) {
                setUserLockEMetisBalance(
                    new BigNumber(
                        userInfo?.eMetisBalance?.toString(),
                    ).shiftedBy(-eMetis.decimals),
                );
            }

            if (userInfo?.claimedAmount) {
                setClaimedAmount(
                    new BigNumber(
                        userInfo?.claimedAmount?.toString(),
                    ).shiftedBy(-eMetis.decimals),
                );
            }
        } catch (e) {
            console.error('get vesting getUserInfo error');
            console.error(e);
        }
    }

    async function getCanClaimAbleAmount() {
        if (!vestingContract || !user.address) return;
        try {
            const claimable = await vestingContract?.claimable(user.address);

            setClaimAbleAmount(
                new BigNumber(claimable?.toString()).shiftedBy(-enki.decimals),
            );
        } catch (e) {
            console.error('getCanClaimAbleAmount error');
            console.error(e);
        }
    }

    async function getEstimateFinishedTime() {
        if (!vestingContract || !user.address) return;
        try {
            const res = await vestingContract?.estimateFinishTime(user.address);

            setEstimateFinishedTime((res?.toNumber() || 0) * 1000);
        } catch (e) {
            console.error('getEstimateFinishedTime error');
            console.error(e);
        }
    }

    useEffect(() => {
        if (vestingContract && user.address) {
            getUserInfo();
            getCanClaimAbleAmount();
        }
    }, [vestingContract, user.address]);

    useEffect(() => {
        if (
            vestingContract &&
            user.address &&
            userLockEMetisBalance.isGreaterThan(0)
        ) {
            getEstimateFinishedTime();
        }
    }, [vestingContract, user.address, userLockEMetisBalance]);

    const leftDays = useMemo(() => {
        if (!lastVestingTime || !estimateFinishedTime) return 0;
        const endTime = moment(estimateFinishedTime);
        const now = moment();
        if (now.isAfter(endTime)) return 0;
        return endTime.diff(now, 'days');
    }, [lastVestingTime, estimateFinishedTime]);

    return {
        vestingContract,
        userStakeENKIBalance,
        getCanClaimAbleAmount,
        claimableAmnount,
        lastVestingTime,
        cumulativeClaimAmount,
        getUserInfo,
        leftDays,
        userLockEMetisBalance,
        claimedAmount,
        estimateFinishedTime,
        getEstimateFinishedTime,
    };
}
