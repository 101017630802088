import React, { useState, useRef, useCallback, useEffect } from 'react';
import classNames from  'classnames';
import styled from 'styled-components';

const StyledButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    color: #fff;
    border-radius: 42px;
    font-size: 18px;
    padding: 16px 38px;
    min-width: 220px;
    min-height: 64px;
    cursor: pointer;

    &.default {
        background: transparent;
        border: 1px solid ${({ theme } ) => theme.c.white02};
        color: ${({ theme } ) => theme.c.white01};

    }

    &.normal {
        background: transparent;
        border: 1px solid ${({ theme } ) => theme.c.mainColor};
        color: ${({ theme } ) => theme.c.mainColor};
    }

    &.primary {
        background: ${({ theme } ) => theme.c.bg02};
        border: none;
        color: ${({ theme } ) => theme.c.black01};
    }
`;

interface Props {
    size?: 'middle' | 'normal';
    type?: 'default' | 'normal' | 'primary';
    disabled?: boolean;
    children?: React.ReactNode;
    className?: string;
    showLoading?: boolean;
    loadingProps?: {
        classname?: string;
        size?: number;
    };
    style?: React.CSSProperties;
    onClick?:
        | ((event?: React.SyntheticEvent) => void)
        | ((event?: React.SyntheticEvent) => Promise<any>);
}

export default function Button(props: Props) {
    const {
        size = 'normal',
        type = 'default',
        disabled,
        children,
        className,
        onClick,
        showLoading = true,
        loadingProps = {},
        ...rest
    } = props;

    const [ loading, setLoading ] = useState(false);
    const loadingRef = useRef(loading);
    const mountedRef = useRef(true);

    loadingRef.current = loading;

    const handleLoading = useCallback(
        (value: any) => {
            if (mountedRef) {
                setLoading(value);
            }
        }
    , [setLoading])

    const handleClick = useCallback(
        (event: any) => {
            if (loadingRef.current || disabled || !onClick) {
                return;
            }

            const promise = onClick(event);
            if (
                promise != null &&
                typeof (promise as Promise<any>).then === 'function'
            ) {
                handleLoading(true);
                (promise as Promise<any>)
                    .then((result: any) => {
                        handleLoading(false);
                        return result;
                    })
                    .catch((e) => {
                        handleLoading(false);
                    });
            }
        },
        [loadingRef, mountedRef, disabled, handleLoading, onClick],
    );

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const classes = [
        size,
        type,
        disabled && 'disabled',
        loading && showLoading && 'loading',
        className,
    ];

    return (
        <StyledButton
            className={classes.join(' ')}
            onClick={handleClick}
            {...rest}
        >
            {children}
        </StyledButton>
    )
}
