import { makeAutoObservable } from 'mobx';
import { Confirm } from 'components/Dialog';

type SimpleDialogType = 'success' | 'error' | 'info';

interface DialogProps {
    type: SimpleDialogType;
    title: string;
    text: string;
    confirm?: Confirm;
    hideCancel?: boolean;
    onClose?(): void;
}

type ActionProps = Omit<DialogProps, 'type'>;

export class Dialog {
    isOpen: boolean = false;
    type: SimpleDialogType = 'info';
    title: string = '';
    text: string = '';
    confirm?: Confirm;
    hideCancel?: boolean = false;
    onClose?: () => void;
    constructor() {
        makeAutoObservable<this>(this);
    }

    open = ({
        type,
        title,
        text,
        confirm,
        onClose,
        hideCancel,
    }: DialogProps) => {
        this.isOpen = true;
        this.type = type;
        this.title = title;
        this.confirm = confirm;
        this.text = text;
        this.onClose = onClose;
        this.hideCancel = hideCancel;
    };

    success = (props: ActionProps) => {
        this.open({
            type: 'success',
            ...props,
        });
    };

    error = (props: ActionProps) => {
        this.open({
            type: 'error',
            ...props,
        });
    };

    close = () => {
        this.isOpen = false;
        // this.title = '';
        this.confirm = undefined;
        if (this.onClose) {
            this.onClose();
        }
        this.onClose = undefined;
        this.hideCancel = false;
        // this.text = '';
        // this.type = 'success';
    };
}

export default new Dialog();
