import { Fade, Menu } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const Wrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    align-items: center;
    width: 100%;
    cursor: pointer;
`;

interface Props {
    children: React.ReactNode;
    dropItems: React.ReactNode[];
}

const StyledMenu = styled(Menu)`
    border-radius: 12px;
    .MuiPaper-root {
        transform: translate(-50%, 0);
    }
    .MuiList-root {
        padding: 0;
    }
`;

const MenuContent = styled.div`
    width: fit-content;
    padding: 14px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    background: #000000;
`;

const MenuRow = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;
    cursor: pointer;

    img {
        width: 12px;
        height: 12px;
    }

    span {
        color: #0d0102;
        font-size: 14px;
    }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
    color: #dca45c;
`;

export default function DropMenu({ children, dropItems }: Props) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isOpenMenu = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Wrapper onClick={handleClick}>
                {children} <StyledKeyboardArrowDownIcon />
            </Wrapper>
            <StyledMenu
                id="fade-menu"
                MenuListProps={{
                    'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={isOpenMenu}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuContent>
                    {dropItems.map((item, index) => (
                        <MenuRow key={index}>{item}</MenuRow>
                    ))}
                </MenuContent>
            </StyledMenu>
        </>
    );
}
