import React from 'react';
import Button from 'components/Button';
import styled from 'styled-components';
import { observer } from 'mobx-react';
import user from 'stores/user';
import { useConnectModal } from '@rainbow-me/rainbowkit';

const StyledButton = styled(Button)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px !important;
    /* padding: 15px 30px !important; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px 8px !important;

    box-shadow: none !important;
`;

interface Props {
    className?: string;
}

function ConnectWallet({ className = '' }: Props) {
    const { openConnectModal } = useConnectModal();
    if (user.connected) return null;

    return (
        <>
            <StyledButton
                size="middle"
                type="outline"
                onClick={openConnectModal}
                className={className}
            >
                Connect Wallet
            </StyledButton>
        </>
    );
}

export default observer(ConnectWallet);
