import {
    metisConfig,
    eMetisConfig,
    seMetisConfig,
    enkiConfig,
} from 'constant/token';
import { useMemo } from 'react';
import contracts from 'stores/contracts';
import user from 'stores/user';

export function useConstantToken() {
    const { chainId } = user;

    return useMemo(() => {
        return {
            metis: {
                ...metisConfig[chainId],
                address: contracts.contracts?.l2Metis || '',
            },
            eMetis: {
                ...eMetisConfig[chainId],
                address: contracts.contracts?.eMetis || '',
            },
            seMetis: {
                ...seMetisConfig[chainId],
                address: contracts.contracts?.seMetis || '',
            },
            enki: {
                ...enkiConfig[chainId],
                address: contracts.contracts?.enki || '',
            },
        };
    }, [chainId, contracts.contracts]);
}
