import { defineChain } from 'viem';

export const metisSepolia = defineChain({
    id: 59902,
    name: 'Metis Sepolia',
    nativeCurrency: {
        decimals: 18,
        name: 'Metis Sepolia',
        symbol: 'METIS',
    },
    rpcUrls: {
        default: {
            http: ['https://sepolia.metisdevops.link'],
            webSocket: ['https://sepolia.metisdevops.link'],
        },
        public: {
            http: ['https://sepolia.metisdevops.link'],
            webSocket: ['https://sepolia.metisdevops.link'],
        },
    },
    blockExplorers: {
        default: {
            name: 'Explorer',
            url: 'https://sepolia-explorer.metisdevops.link',
        },
    },
    contracts: {
        multicall3: {
            address: '0xcA11bde05977b3631167028862bE2a173976CA11',
            blockCreated: 71379,
        },
    },
});
