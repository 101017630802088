import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import icDownImg from 'assets/images/home/ic_down.png';
import icLeftImg from 'assets/images/home/ic_left.png';
import icRightImg from 'assets/images/home/ic_right.png';
import icStakeMetisImg from 'assets/images/home/ic_Stake Metis.png';
import icMinteMetisImg from 'assets/images/home/ic_Mint eMetis.png';
import icseMetisImg from 'assets/images/home/ic_Receive seMetis.png';
import icMaximizeRewardsImg from 'assets/images/home/ic_Maximize Rewards.png';
import lineLeftImg from 'assets/images/home/line_left.png';
import lineRightImg from 'assets/images/home/line_right.png';
import lineDownImg from 'assets/images/home/line_down.png';
import lineUpImg from 'assets/images/home/line_up.png';
import { isMobile } from 'react-device-detect';

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    padding: 0 10px;
    `};
`;

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 90px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: 100%;
    `};
`;

const Item = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 166px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    width: fit-content;
    `};

    &.arrowItem {
        height: 166px;
        justify-content: center;
    }

    .itemIc {
        height: 166px;
        width: 166px;

        ${({ theme }) => theme.mediaWidth.upToSmall`
        width: 40px;
        height: 40px;
        `};
    }

    > * {
        z-index: 2;
    }

    .bgArrow {
        position: absolute;
        z-index: 1;
    }

    .topMiddleArrow {
        margin-left: 22px;
        margin-right: 22px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
        margin: 0;
        `};
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
`;

export default function Rewards() {
    return (
        <Wrapper>
            <TYPE.main
                color="white01"
                fontSize={40}
                fontWeight="bold"
                textAlign="center"
                mobileFz={28}
            >
                How ENKI provides rewards
            </TYPE.main>

            <TYPE.main
                color="white01"
                fontSize={16}
                textAlign="center"
                marginTop="12"
            >
                With ENKI, your Metis can be staked to earn you the best rewards
            </TYPE.main>

            <Panel>
                <Item>
                    <img className="itemIc" src={icStakeMetisImg} />
                    <TYPE.main
                        color="mainColor"
                        fontSize={16}
                        fontWeight="bold"
                        marginTop={16}
                    >
                        Stake Metis
                    </TYPE.main>
                    <TYPE.main
                        color="desc01"
                        fontSize={14}
                        textAlign={'center'}
                        marginTop={12}
                    >
                        Stake any amount of your Metis
                    </TYPE.main>
                    <img
                        style={{ height: 50, marginTop: 12 }}
                        src={lineDownImg}
                    />
                    <img
                        className="bgArrow"
                        style={{ width: '100%', bottom: 18, left: 0 }}
                        src={icDownImg}
                    />
                </Item>

                <Row style={{ marginTop: 10 }}>
                    <Item>
                        <img className="itemIc" src={icseMetisImg} />
                        <TYPE.main
                            color="mainColor"
                            fontSize={16}
                            fontWeight="bold"
                            marginTop={16}
                            textAlign={'center'}
                        >
                            Receive seMetis
                        </TYPE.main>
                        <TYPE.main
                            color="desc01"
                            fontSize={14}
                            textAlign={'center'}
                            marginTop={12}
                        >
                            Stake eMetis into seMetis vault will automatically
                            accrue sequencer rewards
                        </TYPE.main>
                    </Item>

                    <Item className="arrowItem">
                        <img
                            className="topMiddleArrow"
                            src={lineLeftImg}
                            style={{ width: isMobile ? 40 : 138 }}
                        />
                    </Item>

                    <Item>
                        <img className="itemIc" src={icMinteMetisImg} />
                        <TYPE.main
                            color="mainColor"
                            fontSize={16}
                            fontWeight="bold"
                            marginTop={16}
                            textAlign={'center'}
                        >
                            Mint eMetis
                        </TYPE.main>
                        <TYPE.main
                            color="desc01"
                            fontSize={14}
                            textAlign={'center'}
                            marginTop={12}
                        >
                            1:1 Mint eMetis that loosely pegged to Metis
                        </TYPE.main>
                        <img
                            className="bgArrow"
                            style={{ height: 153, top: 3, left: -146 }}
                            src={icLeftImg}
                        />
                        <img
                            className="bgArrow"
                            style={{ height: 153, top: 3, right: -146 }}
                            src={icRightImg}
                        />
                    </Item>

                    <Item className="arrowItem">
                        <img
                            className="topMiddleArrow"
                            src={lineRightImg}
                            style={{ width: isMobile ? 40 : 138 }}
                        />
                    </Item>

                    <Item>
                        <img className="itemIc" src={icMaximizeRewardsImg} />
                        <TYPE.main
                            color="mainColor"
                            fontSize={16}
                            fontWeight="bold"
                            marginTop={16}
                            textAlign={'center'}
                        >
                            Maximize Rewards
                        </TYPE.main>
                        <TYPE.main
                            color="desc01"
                            fontSize={14}
                            textAlign={'center'}
                            marginTop={12}
                        >
                            Use your eMetis/seMetis in DeFi to maximize returns
                        </TYPE.main>
                    </Item>
                </Row>
                <Item>
                    <img
                        src={lineUpImg}
                        style={{ width: isMobile ? '72%' : 712 }}
                    />
                    <img
                        className="bgArrow"
                        src={icRightImg}
                        style={{
                            top: -29,
                            left: '50%',
                            transform: 'translateX(-50%)',
                            height: 153,
                        }}
                    />
                </Item>
            </Panel>
        </Wrapper>
    );
}
