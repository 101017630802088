import React from 'react';
import styled from 'styled-components';
import { TYPE } from 'theme';
import auditsBgImg from 'assets/images/home/audits_bg.png';
import hackenLogoImg from 'assets/images/home/hachen.png';
import armorsLogoImg from 'assets/images/home/armors.jpeg';

const Wrapper = styled.a`
    display: flex;
    flex-direction: column;
    background: url(${auditsBgImg}) no-repeat;
    background-position: center 200px;
    background-size: 100% 100%;
    padding-bottom: 88px;
`;

const AuditsList = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 72px;
    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin-top: 20px;
    `};
`;

const AuditsItem = styled.div`
    width: 400px;
    height: 340px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(0, 0, 0, 0.7);
    border-radius: 32px;
    padding: 40px 40px 25px 40px;
    margin: 0 40px;

    ${({ theme }) => theme.mediaWidth.upToSmall`
    margin: 0;
    width: 45%;
    padding: 20px;
    height: fit-content;
    `};

    .topPart {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .auditLogo {
            height: 100px;
        }
    }

    .bottomPart {
        position: relative;
        padding-top: 20px;
        border-top: 1px dashed ${({ theme }) => theme.c.mainColor};
        width: 100%;
        a {
            text-decoration: none;
        }
        ${({ theme }) => theme.mediaWidth.upToSmall`
        margin-top: 20px;
        `};
    }
`;

export default function Audits() {
    return (
        <Wrapper id="audits">
            <TYPE.main
                color="white01"
                fontSize={40}
                fontWeight="bold"
                textAlign="center"
                mobileFz={28}
            >
                Audits
            </TYPE.main>

            <TYPE.main color="white01" fontSize={16} textAlign={'center'}>
                ENKI has been audited by leading auditing firms
            </TYPE.main>

            <AuditsList>
                <AuditsItem>
                    <div className="topPart">
                        <img className="auditLogo" src={hackenLogoImg} />
                        <TYPE.main
                            color="mainColor"
                            fontSize={16}
                            marginTop={40}
                            textAlign={'center'}
                        >
                            Mar 12, 2024
                        </TYPE.main>
                    </div>

                    <div className="bottomPart">
                        <a
                            href="https://hacken.io/audits/enki/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TYPE.main
                                color="mainColor"
                                fontSize={16}
                                textAlign={'center'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {}}
                            >
                                See Report &gt;&gt;
                            </TYPE.main>
                        </a>
                    </div>
                </AuditsItem>

                <AuditsItem>
                    <div className="topPart">
                        <img className="auditLogo" src={armorsLogoImg} />
                        <TYPE.main
                            color="mainColor"
                            fontSize={16}
                            marginTop={40}
                            textAlign={'center'}
                        >
                            Dec 19, 2023
                        </TYPE.main>
                    </div>

                    <div className="bottomPart">
                        <a
                            href="http://odex.vip/?sign=9c65ba809188969ca834a3499bca3464"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <TYPE.main
                                color="mainColor"
                                fontSize={16}
                                textAlign={'center'}
                                style={{ cursor: 'pointer' }}
                                onClick={() => {}}
                            >
                                See Report &gt;&gt;
                            </TYPE.main>
                        </a>
                    </div>
                </AuditsItem>
            </AuditsList>
        </Wrapper>
    );
}
