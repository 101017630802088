import { ADDRESS_ZERO } from 'constant';
import { useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import user from 'stores/user';
import { decompressString } from 'utils/compress';

export function useGetInviterAddress() {
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    return useMemo(() => {
        const code = searchParams.get('code');
        if (code) {
            const address = decompressString(code);
            if (address.toLowerCase() === user.address.toLowerCase()) {
                return ADDRESS_ZERO;
            } else {
                return address;
            }
        } else {
            return ADDRESS_ZERO;
        }
    }, [searchParams]);
}
