/* eslint-disable react-hooks/exhaustive-deps */
import getTokens from 'gql/subgraph/tokens';

import { useEffect, useMemo } from 'react';

import { isAddress } from 'ethers/lib/utils';
import tokenInfo from 'stores/tokenInfo';
import user from 'stores/user';
import { ChainId } from 'constant';
//// chainId
export default function useTokenPrice() {
    const { fetchTokenList } = tokenInfo;

    async function getTokenInfo() {
        const ids = fetchTokenList.map((item) => item.toLowerCase());
        if (!ids.length) return;
        const res = await getTokens(ids, user.chainId);

        if (res) {
            tokenInfo.setTokensPrice(res);
        }
    }

    useEffect(() => {
        getTokenInfo();
        const interval = setInterval(() => {
            getTokenInfo();
        }, 30000);

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [fetchTokenList, user.chainId]);
}

export function useGetTokenPrice(address: string) {
    const { chainId } = user;
    const { tokenPrice } = tokenInfo;

    const addr = useMemo(() => {
        let addr = '';
        if (isAddress(address)) {
            addr = address;
        }

        return addr;
    }, [address]);

    useEffect(() => {
        if (addr) {
            tokenInfo.addFetchTokens([addr.toLowerCase()]);
        }
    }, [addr]);

    return useMemo(() => {
        if (chainId !== ChainId.mainnet) return 1;
        return addr && tokenPrice && tokenPrice[addr.toLowerCase()]
            ? tokenPrice[addr.toLowerCase()].price
            : undefined;
    }, [tokenPrice, addr, chainId]);
}

export function useGetTokensPrice(tokenAddress: string[]) {
    const { tokenPrice } = tokenInfo;

    useEffect(() => {
        tokenInfo.addFetchTokens(
            tokenAddress
                .filter((item) => !!item)
                .map((item) => item.toLowerCase()),
        );
    }, [tokenAddress]);

    return tokenPrice;
}
