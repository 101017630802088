/* eslint-disable no-script-url */
import React, { useState } from 'react';
import { formatHashValue } from 'utils';

import ConnectWalletButton from 'pages/components/ConnectWalletButton';
import AccountDetail from 'components/AccountDetail';
import styled from 'styled-components';
import makeBlockie from 'ethereum-blockies-base64';
import { TYPE } from 'theme';
import useTokenBalance from 'hooks/useTokenBalance';
import { useConstantToken } from 'hooks/useConstantToken';
import { observer } from 'mobx-react';
import WalletDropMenu from 'components/AccountDetail/WalletDropMenu';
import Button from 'components/Button';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
const AccountButton = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px !important;
    padding: 0 0 0 10px !important;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.c.bg02};
    color: #9393aa;

    border-radius: 15px;
    overflow: hidden;
`;

const Address = styled.div`
    background: transparent;
    height: 100%;
    display: flex !important;
    align-items: center;
    color: #fff;
    gap: 12px;
    border-radius: 15px;

    img {
        width: 24px;
        height: 24px;
        border-radius: 12px;
    }
`;

const StyledKeyboardArrowDownIcon = styled(KeyboardArrowDownIcon)`
    color: #fff;
`;

interface Props {
    connected: boolean;
    walletAddress: string;
    className?: string;
}

function ConnectButton({ connected, walletAddress, className }: Props) {
    const [isOpenAccountDetail, setIsOpenAccountDetail] = useState(false);
    const { metis } = useConstantToken();

    return (
        <>
            {!connected || !walletAddress ? (
                <ConnectWalletButton className={className} />
            ) : (
                <WalletDropMenu>
                    <Button type="outlineWhite">
                        <Address>
                            <img src={makeBlockie(walletAddress)} alt="" />

                            <TYPE.main
                                color={'#fff'}
                                fontSize={12}
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                {formatHashValue(walletAddress)}
                            </TYPE.main>
                        </Address>
                        <StyledKeyboardArrowDownIcon />
                    </Button>
                </WalletDropMenu>
            )}
            <AccountDetail
                isOpen={isOpenAccountDetail}
                onClose={() => setIsOpenAccountDetail(false)}
            />
        </>
    );
}

export default observer(ConnectButton);
