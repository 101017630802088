import React from 'react';
import styled from 'styled-components';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from 'components/Button';
import { TYPE } from 'theme';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const Wrapper = styled(Dialog)`
    .MuiDialog-paper {
        background: linear-gradient(
            180deg,
            #fafafa 0%,
            #5a382f 0%,
            #c77353 100%
        );
        min-width: 540px;
        padding: 30px;
        box-sizing: border-box;
        border-radius: 16px;
        ${({ theme }) => theme.mediaWidth.upToSmall`
    min-width: unset;
    padding: 0;
    margin: 0;
    `}
    }
`;

const StyledDialogActions = styled(DialogActions)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    button {
        padding-left: 40px;
        padding-right: 40px;
    }
`;

const StyledDialogContent = styled(DialogContent)`
    color: ${({ theme }) => theme.c.t01};
    margin: 20px 0;
`;

export interface Confirm {
    text?: string;
    action?(): void;
}

export interface CDialogProps extends DialogProps {
    confirm?: Confirm;
    close?(): void;
    hideCancel?: boolean;
    hideAllButton?: boolean;
}
export default function CDialog({
    open,
    title,
    onClose,
    confirm,
    children,
    hideCancel,
    hideAllButton,
}: CDialogProps) {
    async function onConfirm() {
        if (confirm?.action) {
            await (confirm.action as any)();
        }

        onClose && (onClose as any)();
    }
    return (
        <Wrapper open={open} onClose={onClose}>
            <DialogTitle>
                <TYPE.main fontSize={20} fontWeight={600} textAlign="center">
                    {title}
                </TYPE.main>
            </DialogTitle>
            <StyledDialogContent>{children}</StyledDialogContent>
            {!hideAllButton && (
                <StyledDialogActions>
                    {!hideCancel && (
                        <Button onClick={onClose as any}>Cancel</Button>
                    )}
                    <Button onClick={onConfirm}>
                        {confirm?.text || 'Confirm'}
                    </Button>
                </StyledDialogActions>
            )}
        </Wrapper>
    );
}

const SimpleDialogContent = styled(DialogContent)`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
`;

const SuccessIcon = styled(CheckCircleIcon)`
    fill: #26d962;
    width: 48px;
    height: 48px;
`;

const ErrorIcon = styled(CancelIcon)`
    fill: #eb4747;
    width: 48px;
    height: 48px;
`;

interface SimpleCDialogProps extends CDialogProps {
    type: 'success' | 'error' | 'info';
    text?: string;
}

export function SimpleCDialog({
    type,
    text,
    title,
    hideCancel,
    confirm,
    onClose,

    ...remain
}: SimpleCDialogProps) {
    async function onConfirm() {
        if (confirm?.action) {
            await (confirm.action as any)();
        }

        onClose && (onClose as any)();
    }
    return (
        <Wrapper {...remain}>
            <DialogTitle>
                <TYPE.main
                    fontSize={20}
                    fontWeight={600}
                    color={
                        type === 'info'
                            ? '#000'
                            : type === 'success'
                            ? '#26D962'
                            : '#EB4747'
                    }
                    textAlign="center"
                >
                    {title}
                </TYPE.main>
            </DialogTitle>
            <SimpleDialogContent>
                {type !== 'info' ? (
                    type === 'success' ? (
                        <SuccessIcon />
                    ) : (
                        <ErrorIcon />
                    )
                ) : undefined}

                <TYPE.main
                    marginTop={20}
                    style={{ wordBreak: 'break-word' }}
                    textAlign="center"
                >
                    {text}
                </TYPE.main>
            </SimpleDialogContent>

            <StyledDialogActions>
                {!hideCancel && (
                    <Button onClick={onClose as any}>Cancel</Button>
                )}
                <Button onClick={onConfirm}>
                    {confirm?.text || 'Confirm'}
                </Button>
            </StyledDialogActions>
        </Wrapper>
    );
}
